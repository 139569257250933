import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input, Select,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalOrderEditPayment(props) {
  const [isPaid, setIsPaid] = useState(false);
  const [isViewPaid, setIsViewPaid] = useState(false);
  const inputPaymentType = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/realization-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsViewPaid(!!data && !!data.payment_type);
      setIsPaid(!!data && data.is_paid);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const paymentType = inputPaymentType.current.value;

    const jsonData = JSON.stringify({
      is_paid: paymentType.length === 0 || paymentType === 'null' ? false : isPaid,
      payment_type: paymentType.length === 0 || paymentType === 'null' ? null : paymentType,
      discount_percentage: paymentType === 'CASH' ? 200 : null
    });

    axios
      .put("/api/realization-order/" + props.object.uuid + "/edit/payment", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Реализация</div>
            </div>


            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={inputPaymentType}
                options={[
                  {
                    value: "null",
                    title: "Не выбран",
                  },
                  {
                    value: "CASH",
                    title: "Наличными",
                  },
                  {
                    value: "CARD",
                    title: "Картой или QR",
                  },
                  {
                    value: "ON_CARD_SBER_BANK",
                    title: "Перевод на Сбер",
                  },
                  {
                     value: "ON_CARD_ALFA_BANK",
                     title: "Перевод на Альфу",
                  },
                  {
                     value: "ON_CARD_T_BANK",
                     title: "Перевод на Т-банк",
                  },
                  {
                    value: "CHECK",
                    title: "По счёту",
                  },
                  {
                    value: "PREPAID_EXPENSE",
                    title: "В счëт зарплаты",
                  },
                ]}
                label="Тип оплаты"
                value={
                  !!object.payment_type && object.payment_type
                }
                onChange={(e) => setIsViewPaid(e.target.value.length === 0 || e.target.value === 'null' ? false : true)}
              />
            </div>

            {isViewPaid && (
            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPaid ? "2px solid #ffe082" : "2px dashed #20202015",
                  backgroundColor: isPaid ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPaid(!isPaid)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Оплачен
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPaid && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>
              )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            {/*<div className="Form__actions Form__actions_center">*/}
            {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
            {/*    Отмена*/}
            {/*  </div>*/}
            {/*</div>*/}
          </form>
      </Modal>
    )
  );
}

export default ModalOrderEditPayment;
