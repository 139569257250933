import React, {Fragment, useEffect} from "react";
import {ButtonIcon, ButtonIconExport, getAccountFio, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import moment from "moment";
import RealizationOrderRealizationList from "./RealizationOrderRealizationList";
import {ROOT_URL} from "../../../../config/url";
import {NavLink} from "react-router-dom";
import styleContent from "../../../../styles/modules/Block.module.scss";
import styleCard from "../../../../styles/modules/Card.module.scss";

function RealizationOrderItem(props) {
  const {
    object,
    objectUuid,

    searchQuery,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Add, setModalOrderItem3Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
    modalOrderEditPayment, setModalOrderEditPayment,
    modalOrderEditPaymentNew, setModalOrderEditPaymentNew,
  } = props;

  useEffect(() => {
    if (!!objectUuid && !isUnroll(objectUuid)) {
      changeUnroll(objectUuid);
    }
  }, []);

  function isBoningOrderTransaction(object) {
    let isTransaction = false

    if (!!object.items) {
      object.items.forEach(i => {
        if (i.is_transaction) {
          isTransaction = true
        }
      })
    }

    return isTransaction
  }

  const getCashAmount = (amount) => {
    if (!!amount) {
      return Math.floor(amount - (amount / 100 * (200 / 100)))
    }
    return 0
  }

  return (
    <>
      <li
        className={
          isUnroll(object.uuid) ? "List__item active" : "List__item"
        }
        onClick={() => changeUnroll(object.uuid)}
        style={{
          padding: "10px 12px",
          position: "relative",
          marginBottom: 12
        }}
      >
        <div
          className="Item__title"
          style={{
            width: "100%",
          }}
        >
          <div
            className="Element__title"
            style={{
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 19,
                fontWeight: 700,
                width: "100%",
              }}
            >
              №<TextSearchMarker
              text={object.id}
              search={searchQuery}
            />{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            </div>

            {!!object.contractor && (
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 700,
                  width: "100%",
                  padding: "4px 8px",
                  border: "1px solid #bdbdbd",
                  marginBottom: 6,
                  marginTop: 6,
                  borderRadius: 5,
                }}
              >

                {!!object.contractor.short_name && (
                  <TextSearchMarker
                    text={object.contractor.short_name}
                    search={searchQuery}
                  />
                )}
                <div style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}>
                  {!!object.contractor.name && (
                    <TextSearchMarker
                      text={object.contractor.name}
                      search={searchQuery}
                    />
                  )}
                </div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}>
                              <span style={{marginRight: 8}}>ОГРН:&nbsp;{(!!object.contractor.ogrn && (
                                <TextSearchMarker
                                  text={object.contractor.ogrn}
                                  search={searchQuery}
                                />
                              )) || "-"}</span>
                  <span style={{marginRight: 8}}>ИНН:&nbsp;{(!!object.contractor.inn && (
                    <TextSearchMarker
                      text={object.contractor.inn}
                      search={searchQuery}
                    />
                  )) || "-"}</span>
                  <span style={{marginRight: 8}}>КПП:&nbsp;{(!!object.contractor.kpp && (
                    <TextSearchMarker
                      text={object.contractor.kpp}
                      search={searchQuery}
                    />
                  )) || "-"}</span>
                </div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}>
                              <span
                                style={{marginRight: 8}}>Банк:&nbsp;{(!!object.contractor.bank && object.contractor.bank) || "-"}</span>
                  <span
                    style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!object.contractor.invoice_number && (
                    <TextSearchMarker
                      text={object.contractor.invoice_number}
                      search={searchQuery}
                    />
                  )) || "-"}</span>
                </div>
              </div>
            )}

            {!!object.paymaster && (
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 400,
                  width: "100%",
                  marginTop: 6,
                }}
              >
                <span style={{marginRight: 4}}>Кассир:</span>
                <TextSearchMarker
                  text={getAccountFio(object.paymaster)}
                  search={searchQuery}
                />
              </div>
            )}

            {!object.payment_type && !!object.final_amount && (
              <div
                style={{
                  width: "100%",
                  marginTop: 4,
                  fontWeight: 700,
                  fontSize: 24
                }}
              >
                          <span style={{marginRight: 16}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                              ((object.final_amount - ((!!object.discount_amount && object.discount_amount) || 0)) / 100).toFixed(2)))}

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 17}}>
                            {!!object.final_amount && "(≈ " + new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              ((object.final_amount - ((!!object.discount_amount && object.discount_amount) || 0)) / 100).toFixed(2)))) + ")"}
                            </span>

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>

                <span style={{fontWeight: 400, fontSize: 17}}>
                  {!!object.discount_percentage && (
                    <>
                      Скидка&nbsp;
                      {!!object.discount_percentage && (new Intl.NumberFormat("ru-RU").format(Number(
                        (object.discount_percentage / 100).toFixed(2))) + "%")}
                    </>
                  )}
                </span>
              </div>
            )}

            {!!object.payment_type && !!object.final_amount && (
              <div style={{
                width: "100%",
                backgroundColor:
                  object.payment_type === 'CASH'
                    ? "#4CAF5033" // светло-зеленый
                    : object.payment_type === 'CARD'
                      ? "#3F51B533" // светло-синий (ближе к банковскому тону)
                      : object.payment_type === 'ON_CARD_SBER_BANK'
                        ? "#46A76D33" // зеленый, более специфичный для Сбера
                        : object.payment_type === 'ON_CARD_ALFA_BANK'
                          ? "#A71B5C33" // красноватый, связанный с Альфа-Банком
                          : object.payment_type === 'ON_CARD_T_BANK'
                            ? "#EF6C0033" // оранжевый, уникальный для Т-банка
                            : object.payment_type === 'CHECK'
                              ? "#FFC10733" // желтый
                              : object.payment_type === 'PREPAID_EXPENSE'
                                ? "#03A9F433" // светло-синий, намек на расходы
                                : "#9E9E9E33", // серый по умолчанию
                padding: "6px 8px 6px",
                borderRadius: 5,
                marginBottom: 6,
                marginTop: 6
              }}
              >
                <div style={{
                  fontSize: 13
                }}>Оплата
                </div>
                <div style={{
                  marginRight: 2,
                  fontSize: 15,
                }}>
                  {object.payment_type === 'CASH' && 'Наличными'}
                  {object.payment_type === 'CARD' && 'Картой или QR'}
                  {object.payment_type === 'ON_CARD_SBER_BANK' && 'Перевод на Сбер'}
                  {object.payment_type === 'ON_CARD_ALFA_BANK' && 'Перевод на Альфу'}
                  {object.payment_type === 'ON_CARD_T_BANK' && 'Перевод на Т-банк'}
                  {object.payment_type === 'CHECK' && 'По счёту'}
                  {object.payment_type === 'PREPAID_EXPENSE' && 'В счëт зарплаты'}
                </div>
                <div style={{
                  fontSize: 24,
                  fontWeight: 700
                }}>
                  {(object.payment_type === 'CASH' ||
                    object.payment_type === 'ON_CARD_SBER_BANK' ||
                    object.payment_type === 'ON_CARD_ALFA_BANK' ||
                    object.payment_type === 'ON_CARD_T_BANK') && (
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 32}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              ((object.final_amount - ((!!object.discount_amount && object.discount_amount) || 0)) / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                      <span style={{fontWeight: 400, fontSize: 17}}>
                  {!!object.discount_percentage && (
                    <>
                      Скидка&nbsp;
                      {!!object.discount_percentage && (new Intl.NumberFormat("ru-RU").format(Number(
                        (object.discount_percentage / 100).toFixed(2))) + "%")}
                    </>
                  )}
                </span>
                    </div>
                  )}

                  {(object.payment_type === 'CARD' ||
                    object.payment_type === 'CHECK' ||
                    object.payment_type === 'PREPAID_EXPENSE') && (
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                    <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 32}}>
                           {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                             ((object.final_amount - ((!!object.discount_amount && object.discount_amount) || 0)) / 100).toFixed(2)))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                      <span style={{fontWeight: 400, fontSize: 17}}>
                  {!!object.discount_percentage && (
                    <>
                      Скидка&nbsp;
                      {!!object.discount_percentage && (new Intl.NumberFormat("ru-RU").format(Number(
                        (object.discount_percentage / 100).toFixed(2))) + "%")}
                    </>
                  )}
                </span>
                    </div>
                  )}
                </div>
              </div>
            )}


            {!!object.items && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginTop: 2,
                  height: 22
                }}
              >
                {/*{object.items.map((i, index) => (*/}
                {/*  <div key={"k_" + object.uuid + ":" + i.uuid}*/}
                {/*       style={{marginTop: 3, marginBottom: 3}}>*/}
                {/*    <div style={{*/}
                {/*      fontSize: 12,*/}
                {/*      backgroundColor: !i.is_transaction ? "#0061fc33" : "#20202033",*/}
                {/*      display: "inline-block",*/}
                {/*      color: "#202020",*/}
                {/*      padding: "2px 4px 2px",*/}
                {/*      borderRadius: 3,*/}
                {/*      margin: "0 2px",*/}
                {/*    }}>*/}
                {/*      {index + 1} # {i.nomenclature.name}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*))}*/}

                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Позиции&nbsp;-&nbsp;
                </div>
                {object.items.length > 0 && (
                  <>
                    <div style={{
                      fontSize: 15,
                      fontWeight: 500,
                      marginRight: 4,
                    }}>
                      {object.items.length}
                    </div>

                    {(object.items.length - object.items.filter(item => item.is_transaction).length) > 0 && (
                      <div style={{
                        fontSize: 15,
                        fontWeight: 700,
                        backgroundColor: "#0061ff",
                        // backgroundColor: object.is_paid ? "#ff0000b3" : "#0061ff",
                        display: "inline-block",
                        color: "white",
                        padding: "1px 6px 1px",
                        borderRadius: 24,
                        margin: "0 2px",
                        border: "1px solid #0043ab",
                        // border: object.is_paid ? "1px solid #ff0000" : "1px solid #0043ab",
                      }}>
                        {(object.items.length - object.items.filter(item => item.is_transaction).length)}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                right: 0,
                justifyContent: "flex-start",
                width: "100%",
                padding: "0 8px",
              }}
            >
              {object.is_paid && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#ffe082",
                    display: "inline-block",
                    color: "#202020",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  Оплачен!
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                left: 0,
                justifyContent: " flex-end",
                width: "100%",
                padding: "0 8px",
              }}
            >
            </div>
          </div>
        </div>
        <div className="Item__settings">
          <div className="Element__datetime">
          </div>
        </div>
      </li>

      {isUnroll(object.uuid) && (
        <li className="List__content" style={{
          backgroundColor: "#00000005",
          border: "1px solid #00000005",
          borderRadius: 7,
          marginBottom: 6,
          paddingTop: 6,
          paddingRight: 14,
          paddingLeft: 14,
        }}>
          <div className="Content__header">
            <div className="Header__title">
              {!object.is_paid && (
                <div
                  className="Button__text Button__text_right"
                  // onClick={() => setModalOrderItem2Add(object)}
                  onClick={() => setModalOrderItem3Add(object)}
                >
                  Добавить позицию
                </div>
              )}

              {/*{!object.is_paid && (*/}
              {/*  <div*/}
              {/*    className="Button__text Button__text_right"*/}
              {/*    onClick={() => setModalOrderItem3Add(object)}*/}
              {/*  >*/}
              {/*    Поиск по QR*/}
              {/*  </div>*/}
              {/*)}*/}

              <div
                className="Button__text Button__text_right"
                onClick={() => setModalOrderEditPayment(object)}
              >
                Отметка об оплате
              </div>
            </div>
            <div className="Header__settings">
              <ButtonIconExport
                tooltipText={"Расходная накладная"}
                iconName={"custom-download"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 20}}
                apiUrl={"/api/realization-order/" + object.uuid + "/export/print"}
                rootUrl={ROOT_URL}
                mimeType={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                fileName={"№" + object.id + (!!object.date && '_от_' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY")) + ".xlsx"}
              />
              <ButtonIconExport
                tooltipText={"Товарная накладная"}
                iconName={"custom-archive"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 20}}
                apiUrl={"/api/realization-order/" + object.uuid + "/export/print/packing-list"}
                rootUrl={ROOT_URL}
                mimeType={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                fileName={"№" + object.id + (!!object.date && '_от_' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY")) + ".xlsx"}
              />

              {!(isBoningOrderTransaction(object) || object.is_paid) && (
                <ButtonIcon
                  tooltipText={"Редактировать"}
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  style={{marginLeft: 12}}
                  onClick={() => setModalItemEdit(object)}
                />
              )}

              {!objectUuid && (
                <NavLink
                  style={{marginLeft: 12}}
                  className="Button__text"
                  to={"/realization/" + object.uuid}>
                  Подробнее
                </NavLink>
              )}
            </div>
          </div>

          {!!object.final_amount && !object.payment_type && (
            <div className={styleContent._block + " " + styleContent._block_bottom}>
              <div className={styleContent._block__title}
                   style={{
                     alignItems: "center"
                   }}>
                Оплата
              </div>

              <div className={styleContent._block__content}
                   style={{
                     alignItems: "center"
                   }}>
                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#4CAF5033",
                       border: "2px solid #4CAF5033",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "CASH"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    Наличными (-2%)
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                            {!!object.final_amount &&
                              new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                                (getCashAmount(object.final_amount) / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>

                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#3F51B533",
                       border: "2px solid #3F51B533",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "CARD"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    Картой или QR
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                           {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                             (object.final_amount / 100).toFixed(2)))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>

                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#46A76D33",
                       border: "2px solid #46A76D33",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "ON_CARD_SBER_BANK"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    Перевод на Сбер
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              (object.final_amount / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>

                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#A71B5C33",
                       border: "2px solid #A71B5C33",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "ON_CARD_ALFA_BANK"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    Перевод на Альфу
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              (object.final_amount / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>

                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#EF6C0033",
                       border: "2px solid #EF6C0033",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "ON_CARD_T_BANK"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    Перевод на Т-банк
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              (object.final_amount / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>

                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#FFC10733",
                       border: "2px solid #FFC10733",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "CHECK"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    По счёту
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                           {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                             (object.final_amount / 100).toFixed(2)))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>

                <div className={styleCard._block}
                     style={{
                       borderRadius: 7,
                       margin: "0px 8px 6px 0",
                       boxSizing: "border-box",
                       backgroundColor: "#03A9F433",
                       border: "2px solid #03A9F433",
                       cursor: "pointer"
                     }}
                     onClick={() => setModalOrderEditPaymentNew({
                       object,
                       type: "PREPAID_EXPENSE"
                     })}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 19,
                    }}
                  >
                    В счëт зарплаты
                  </div>
                  <div
                    className={styleCard._block__title}
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: 4,
                        fontWeight: 700,
                        fontSize: 24
                      }}
                    >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 19}}>
                           {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                             (object.final_amount / 100).toFixed(2)))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          )}


          <RealizationOrderRealizationList
            {...props}
            object={object}
            isUpdateList={isUpdateObject}
            setIsUpdateList={setIsUpdateObject}
          />

          {/*<div className="Content__footer">*/}
          {/*<div className="Footer__title">*/}
          {/*  {!object.is_paid && (*/}
          {/*    <div*/}
          {/*      className="Button__text Button__text_right"*/}
          {/*      onClick={() => setModalOrderItem2Add(object)}*/}
          {/*    >*/}
          {/*      Добавить позицию*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*<div className="Footer__settings">*/}
          {/*</div>*/}
          {/*</div>*/}
        </li>
      )}
    </>
  );
}

export default RealizationOrderItem;
