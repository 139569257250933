import {useEffect, useState} from "react";
import {ROOT_URL} from "../../../../config/url";
import {useAction, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import {useParams} from "react-router-dom";
import useSpaceKey from "../../../../hooks/useSpaceKey";

function useRealizationOrder() {
  let { realizationOrderUuid } = useParams();

  const [isNotFound, setIsNotFound] = useState(false);

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
  } = useObject({
    apiUrl: "/api/realization-order/" + realizationOrderUuid,
    rootUrl: ROOT_URL,
    callbackError: (error) => {
      console.error(error)
      if (error.response.status === 404) {
        setIsNotFound(true);
      }
    }
  });

  const {unrolls, isUnroll, changeUnroll, changeAllUnroll} = useUnroll();

  useAction([realizationOrderUuid], () => updateObject());

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalOrderItem2Add, setModalOrderItem2Add] = useState(null);
  const [modalOrderItem3Add, setModalOrderItem3Add] = useState(null);
  const [modalOrderItem3Edit, setModalOrderItem3Edit] = useState(null);

  const [modalAddBoning, setModalAddBoning] = useState(null);
  const [modalItemTransaction, setModalItemTransaction] = useState(null);
  const [modalItemTransactionRollback, setModalItemTransactionRollback] = useState(null);

  const [modalOrderEditAction, setModalOrderEditAction] = useState(null);

  const [modalOrderEditPayment, setModalOrderEditPayment] = useState(null);
  const [modalOrderEditPaymentNew, setModalOrderEditPaymentNew] = useState(null);

  useSpaceKey(() => {
    setModalOrderItem3Add(object);
  });

  return {
    isNotFound,
    object,
    realizationOrderUuid,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Add, setModalOrderItem3Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
    modalOrderEditPayment, setModalOrderEditPayment,
    modalOrderEditPaymentNew, setModalOrderEditPaymentNew
  };
}

export default useRealizationOrder;
