import { useEffect } from "react";

function useTabKey(onTab) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Проверяем нажатие клавиши Tab
      if (event.key === "Tab") {
        onTab(); // Вызываем переданную функцию
      }
    };

    // Добавляем обработчик события
    window.addEventListener("keydown", handleKeyDown);

    // Убираем обработчик при размонтировании
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onTab]); // Добавляем зависимость

  // Хук ничего не возвращает, только выполняет логику
}

export default useTabKey;
