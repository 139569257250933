import React, {Fragment, useState} from "react";
import {ButtonIcon, getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../styles/modules/Block.module.scss";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";

function RealizationOrderRealizationList(props) {
  const {
    object,

    setModalOrderItem3Edit,

    setModalItemTransaction,
    setModalItemTransactionRollback,
  } = props;

  const [selectComposition, setSelectComposition] = useState(null);

  const isSelectComposition = (composition) => {
    return !!selectComposition && selectComposition.uuid === composition.uuid;
  };

  const {notification} = useNotification();

  const changePriceOptCalculate = (item) => {
    axios
      .get("/api/realization-order-item/" + item.uuid + "/calculate/price-opt", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.setIsUpdateList(!props.isUpdateList);
      })
      .catch((error) => {
        if (!!error.response && !!error.response.data && !!error.response.data.error) {
          notification(getErrorMessageByKey(error.response.data.error), "red");
        }
      });
  };

  return (
    !!object && (
      <Fragment>
        {(!object.items || (!!object.items && object.items.length === 0)) && (
          <div className={styleContent._block} style={{marginTop: 12}}>
            <div className={styleContent._block__content}>
              <span style={{
                fontSize: 24,
                opacity: .25,
                textAlign: "center",
                width: "100%"
              }}>Список позиций пуст</span>
            </div>
          </div>
        )}

        {(!!object.items && object.items.length > 0) && (
          <div className={styleContent._block} style={{marginTop: 12}}>
            <div className={styleContent._block__title}>Позиции</div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                {object.items.map((item, index) => (
                  <Fragment key={"issuance_" + item.uuid}>
                    <tr
                      className={
                        isSelectComposition(item)
                          ? "Table__tr Table__tr_hover Table__tr_focus"
                          : "Table__tr Table__tr_hover"
                      }
                      style={{
                        borderStyle: "2px solid",
                        borderColor: "#bdbdbd",
                        borderBottomColor: isSelectComposition(item)
                          ? "#202020"
                          : "#bdbdbd",
                      }}
                    >
                      <td className="Table__td">
                        <div className="Td__title Td__title_flex">
                          {index + 1} # {!!item.nomenclature && item.nomenclature.name}

                          {!!item.nomenclature.is_pcs && item.nomenclature.is_pcs && (
                            <span
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 0 0 8px",
                              }}
                            >
                              Штучно
                            </span>
                          )}

                          <span style={{
                            marginLeft: 16,
                            fontWeight: 700,
                            fontSize: 24
                          }}>
                                      {!!item.nomenclature && !!item.nomenclature.value && !!item.nomenclature.measurement_unit_type &&
                                        new Intl.NumberFormat("ru-RU").format(Number((!!item.nomenclature.value && !!item.nomenclature.measurement_unit_type ?
                                          (item.nomenclature.value / item.nomenclature.measurement_unit_type.ratio) : !!item.nomenclature.value && item.nomenclature.value).toFixed(3)))}
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      {!!item.nomenclature && !!item.nomenclature.measurement_unit_type && item.nomenclature.measurement_unit_type.designation + '.'}</span>
                                    </span>
                          <span style={{
                            marginLeft: 16,
                            fontWeight: 700,
                            fontSize: 24,
                          }}>
                            <span style={{
                              fontSize: !!item.price_opt && 20,
                              textDecoration: !!item.price_opt && "line-through",
                              opacity: !!item.price_opt && 0.5
                            }}>
                              {(!!item.price && new Intl.NumberFormat("ru-RU").format(Number(
                                (item.price / 100).toFixed(2)))) || "-"}</span>
                            <span style={{
                              marginLeft: 4,
                              fontWeight: 400,
                              fontSize: 13,
                              opacity: !!item.price_opt && 0.5
                            }}>руб.</span></span>

                          {!!item.price_opt && (
                            <span style={{
                              marginLeft: 16,
                              fontWeight: 700,
                              fontSize: 24
                            }}>{(!!item.price_opt && new Intl.NumberFormat("ru-RU").format(Number(
                              (item.price_opt / 100).toFixed(2)))) || "-"}
                              <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                          )}
                        </div>
                      </td>

                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div className="Td__title">
                          {!!item.discount_percentage && (
                            <>
                              Скидка&nbsp;
                              {!!item.discount_percentage && (new Intl.NumberFormat("ru-RU").format(Number(
                            (item.discount_percentage / 100).toFixed(2)))+"%")}
                            </>
                          )}
                        </div>
                      </td>
                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div className="Td__title">
                          <div className={'Button__collected'}>
                            <div
                              className={'Collected__count Collected__count_blue'}>
                              {(!!item.quantity && item.quantity / 1000000) || "0"}</div>
                          </div>
                        </div>
                      </td>
                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>
                                      {!!item.quantity && !!item.nomenclature && !!item.nomenclature.value &&
                                        new Intl.NumberFormat("ru-RU").format(Number((Number(item.quantity / 1000000).toFixed(6) * (!!item.nomenclature.value && !!item.nomenclature.measurement_unit_type ?
                                          (item.nomenclature.value / item.nomenclature.measurement_unit_type.ratio) : !!item.nomenclature.value && item.nomenclature.value)).toFixed(3))) || "0"}
                                      <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      {!!item.nomenclature && !!item.nomenclature.measurement_unit_type && item.nomenclature.measurement_unit_type.designation + '.'}</span>
                                    </span>
                        </div>
                      </td>
                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>{(!!item.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                                      (item.final_amount / 100).toFixed(2)))) || "0"}
                                      <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span>
                                    </span>
                        </div>
                      </td>
                      <td className="Table__td Table__td_child"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        {!item.is_transaction && (
                          <div
                            style={{
                              height: 36,
                              display: "flex",
                            }}
                          >
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              onClick={() =>
                                setModalOrderItem3Edit(item)
                              }
                            />
                          </div>
                        )}

                        {/*{!!item.is_transaction && !item.price_opt && (*/}
                        {/*  <div*/}
                        {/*    style={{*/}
                        {/*      height: 24,*/}
                        {/*      display: "flex",*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    <ButtonIcon*/}
                        {/*      tooltipText={"Установить оптовую цену"}*/}
                        {/*      iconName={"custom-calculate"}*/}
                        {/*      iconColor={"#202020"}*/}
                        {/*      size={24}*/}
                        {/*      onClick={() =>*/}
                        {/*        changePriceOptCalculate(item)*/}
                        {/*      }*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*)}*/}
                      </td>

                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div
                          style={{
                            height: 28,
                            display: "flex",
                          }}
                        >
                          {item.is_transaction && (
                            <div
                              className={
                                "Button__collected"
                              }
                              style={{
                                height: 28,
                                padding: "3px 8px 4px",
                                display: "flex"
                              }}
                            >
                              Учтено
                              <ButtonIcon
                                style={{marginLeft: 12}}
                                tooltipText={"Отменить учёт"}
                                iconName={"edit-undo"}
                                iconColor={"#202020"}
                                size={28}
                                onClick={() =>
                                  setModalItemTransactionRollback(item)
                                }
                              />
                            </div>
                          )}
                          {!item.is_transaction && (
                            <div
                              className={
                                "Button__collected Button__collected_blue"
                                // "Button__collected "+(object.is_paid ? "Button__collected_red" : "Button__collected_blue")
                              }
                              style={{
                                height: 28,
                                padding: "3px 8px 4px",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                setModalItemTransaction(item)
                              }
                            >
                              Учесть
                            </div>
                          )}
                        </div>

                      </td>
                    </tr>
                  </Fragment>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Fragment>
    )
  );
}

export default RealizationOrderRealizationList;
