import React from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {getErrorMessageByKey, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalOrderEditPaymentNew(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/realization-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      is_paid: props.paymentType === 'CASH'
        ? true
        : props.paymentType === 'CARD'
          ? true
          : props.paymentType === 'ON_CARD_SBER_BANK'
            ? true
            : props.paymentType === 'ON_CARD_ALFA_BANK'
              ? true
              : props.paymentType === 'ON_CARD_T_BANK'
                ? true
                : props.paymentType === 'CHECK'
                  ? false
                  : props.paymentType === 'PREPAID_EXPENSE'
                    ? false
                    : false,
      payment_type: props.paymentType,
      discount_percentage: props.paymentType === 'CASH' ? 200 : null
    });

    axios
      .put("/api/realization-order/" + props.object.uuid + "/edit/payment", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getCashAmount = (amount) => {
    if (!!amount) {
      return Math.floor(amount - (amount / 100 * (200 / 100)))
    }
    return 0
  }

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}
             dialogStyle={{
               backgroundColor: "white",
               borderRadius: 11
             }}
             contentStyle={{
               backgroundColor:
                 props.paymentType === 'CASH'
                   ? "#4CAF5033" // светло-зеленый
                   : props.paymentType === 'CARD'
                     ? "#3F51B533" // светло-синий (ближе к банковскому тону)
                     : props.paymentType === 'ON_CARD_SBER_BANK'
                       ? "#46A76D33" // зеленый, более специфичный для Сбера
                       : props.paymentType === 'ON_CARD_ALFA_BANK'
                         ? "#A71B5C33" // красноватый, связанный с Альфа-Банком
                         : props.paymentType === 'ON_CARD_T_BANK'
                           ? "#EF6C0033" // оранжевый, уникальный для Т-банка
                           : props.paymentType === 'CHECK'
                             ? "#FFC10733" // желтый
                             : props.paymentType === 'PREPAID_EXPENSE'
                               ? "#03A9F433" // светло-синий, намек на расходы
                               : "#9E9E9E33", // серый по умолчанию
               borderStyle: "solid",
               borderWidth: 3,
               borderColor:
                 props.paymentType === 'CASH'
                   ? "#4CAF5093" // светло-зеленый
                   : props.paymentType === 'CARD'
                     ? "#3F51B593" // светло-синий (ближе к банковскому тону)
                     : props.paymentType === 'ON_CARD_SBER_BANK'
                       ? "#46A76D93" // зеленый, более специфичный для Сбера
                       : props.paymentType === 'ON_CARD_ALFA_BANK'
                         ? "#A71B5C93" // красноватый, связанный с Альфа-Банком
                         : props.paymentType === 'ON_CARD_T_BANK'
                           ? "#EF6C0093" // оранжевый, уникальный для Т-банка
                           : props.paymentType === 'CHECK'
                             ? "#FFC10793" // желтый
                             : props.paymentType === 'PREPAID_EXPENSE'
                               ? "#03A9F493" // светло-синий, намек на расходы
                               : "#9E9E9E93", // серый по умолчанию
             }}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Реализация</div>
          </div>

          <div className="Form__field">
            <div className="Field__label">
              Оплата
            </div>
            <div className="Field__text" style={{
              fontSize: 24,
              fontWeight: 700
            }}>
              {props.paymentType === 'CASH' && 'Наличными (-2%)'}
              {props.paymentType === 'CARD' && 'Картой или QR'}
              {props.paymentType === 'ON_CARD_SBER_BANK' && 'Перевод на Сбер'}
              {props.paymentType === 'ON_CARD_ALFA_BANK' && 'Перевод на Альфу'}
              {props.paymentType === 'ON_CARD_T_BANK' && 'Перевод на Т-банк'}
              {props.paymentType === 'CHECK' && 'По счёту'}
              {props.paymentType === 'PREPAID_EXPENSE' && 'В счëт зарплаты'}
            </div>
          </div>

          <div className="Form__field">
            <div className="Field__label">
              Сумма
            </div>
            <div className="Field__text" style={{
              fontSize: 24,
              fontWeight: 700
            }}>
              {(props.paymentType === 'CASH') && (
                <div
                  style={{
                    width: "100%",
                    marginTop: 4,
                    fontWeight: 700,
                    fontSize: 24
                  }}
                >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 32}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              (getCashAmount(object.final_amount) / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                </div>
              )}

              {(props.paymentType === 'ON_CARD_SBER_BANK' ||
                props.paymentType === 'ON_CARD_ALFA_BANK' ||
                props.paymentType === 'ON_CARD_T_BANK') && (
                <div
                  style={{
                    width: "100%",
                    marginTop: 4,
                    fontWeight: 700,
                    fontSize: 24
                  }}
                >
                          <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 32}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.floor(Number(
                              (object.final_amount / 100).toFixed(2))))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                </div>
              )}

              {(props.paymentType === 'CARD' ||
                props.paymentType === 'CHECK' ||
                props.paymentType === 'PREPAID_EXPENSE') && (
                <div
                  style={{
                    width: "100%",
                    marginTop: 4,
                    fontWeight: 700,
                    fontSize: 24
                  }}
                >
                <span style={{marginRight: 16}}>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 32}}>
                           {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                             (object.final_amount / 100).toFixed(2)))}
                            </span>
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                </div>
              )}
            </div>
          </div>

          <div className="Form__field Form__field_last">
            <div className="Field__text" style={{
              fontSize: 24,
              fontWeight: 700,
              textAlign: "center"
            }}>
              {(props.paymentType === 'CASH' ||
                props.paymentType === 'CARD' ||
                props.paymentType === 'ON_CARD_SBER_BANK' ||
                props.paymentType === 'ON_CARD_ALFA_BANK' ||
                props.paymentType === 'ON_CARD_T_BANK') && (
                <div
                  style={{
                    width: "100%",
                    marginTop: 4,
                    fontWeight: 500,
                    fontSize: 19
                  }}
                >
                  Отметка об оплате будет установлена сразу
                </div>
              )}

              {(props.paymentType === 'CHECK' ||
                props.paymentType === 'PREPAID_EXPENSE') && (
                <div
                  style={{
                    width: "100%",
                    marginTop: 4,
                    fontWeight: 500,
                    fontSize: 19
                  }}
                >
                  Отметку об оплате нужно установить отдельно!
                </div>
              )}
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue" style={{
              backgroundColor:
                props.paymentType === 'CASH'
                  ? "#4CAF5033" // светло-зеленый
                  : props.paymentType === 'CARD'
                    ? "#3F51B533" // светло-синий (ближе к банковскому тону)
                    : props.paymentType === 'ON_CARD_SBER_BANK'
                      ? "#46A76D33" // зеленый, более специфичный для Сбера
                      : props.paymentType === 'ON_CARD_ALFA_BANK'
                        ? "#A71B5C33" // красноватый, связанный с Альфа-Банком
                        : props.paymentType === 'ON_CARD_T_BANK'
                          ? "#EF6C0033" // оранжевый, уникальный для Т-банка
                          : props.paymentType === 'CHECK'
                            ? "#FFC10733" // желтый
                            : props.paymentType === 'PREPAID_EXPENSE'
                              ? "#03A9F433" // светло-синий, намек на расходы
                              : "#9E9E9E33", // серый по умолчанию
              borderColor:
                props.paymentType === 'CASH'
                  ? "#4CAF5033" // светло-зеленый
                  : props.paymentType === 'CARD'
                    ? "#3F51B533" // светло-синий (ближе к банковскому тону)
                    : props.paymentType === 'ON_CARD_SBER_BANK'
                      ? "#46A76D33" // зеленый, более специфичный для Сбера
                      : props.paymentType === 'ON_CARD_ALFA_BANK'
                        ? "#A71B5C33" // красноватый, связанный с Альфа-Банком
                        : props.paymentType === 'ON_CARD_T_BANK'
                          ? "#EF6C0033" // оранжевый, уникальный для Т-банка
                          : props.paymentType === 'CHECK'
                            ? "#FFC10733" // желтый
                            : props.paymentType === 'PREPAID_EXPENSE'
                              ? "#03A9F433" // светло-синий, намек на расходы
                              : "#9E9E9E33", // серый по умолчанию
              color: "#202020",
            }}>
              {props.paymentType === 'CASH' && 'Оплачен'}
              {props.paymentType === 'CARD' && 'Оплачен'}
              {props.paymentType === 'ON_CARD_SBER_BANK' && 'Подтвердить'}
              {props.paymentType === 'ON_CARD_ALFA_BANK' && 'Подтвердить'}
              {props.paymentType === 'ON_CARD_T_BANK' && 'Подтвердить'}
              {props.paymentType === 'CHECK' && 'Зафиксировать'}
              {props.paymentType === 'PREPAID_EXPENSE' && 'Учесть'}
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalOrderEditPaymentNew;
