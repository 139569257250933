import React, {Fragment, useRef} from "react";
import {
  Modal,
  useList,
  Search,
  TextSearchMarker, ButtonIcon, Select, Pagination, Tooltip, Icon,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../../config/url";

function ModalOrderItemSelect(props) {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/nomenclatures",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 10,
    isSearch: true,
  });

  const changeAdd = (item) => {
    props.successfulRequest(item);
    props.handleClose();
  };

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        width: 740
      }}>
        <div className="Form">
          <div className="Form__title">
            Выбор номенклатуры
          </div>

          <div className="Form__field">
            <div className="Page__title">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 21,
                  fontWeight: 700,
                }}
              >
                Номенклатура
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                  ({count})
                </span>
                )}
                <span style={{width: 220, marginLeft: 16}}>
                <Search
                  reference={inputSearchText}
                  type="text"
                  label={"Поиск"}
                  isFixed={true}
                  onChange={() => onChangeSearchText()}
                />
              </span>
              </div>

              <div className="Title__settings">
                <ButtonIcon
                  tooltipText={"Обновить"}
                  iconName={"custom-change-circle"}
                  iconColor={"#20202075"}
                  size={28}
                  style={{marginLeft: 8}}
                  onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
                />

                {!!count && !!pageLimit && (
                  <div
                    style={{
                      marginLeft: 12,
                      width: 64,
                    }}
                  >
                    <Select
                      isFixed={true}
                      isDefaultOption={false}
                      value={pageLimit}
                      options={[
                        {
                          value: 10,
                          title: 10,
                        },
                        {
                          value: 25,
                          title: 25,
                        },
                        {
                          value: 50,
                          title: 50,
                        },
                        {
                          value: 75,
                          title: 75,
                        },
                        {
                          value: 100,
                          title: 100,
                        },
                      ]}
                      label="Лимит"
                      onChange={(event) => setPageLimit(Number(event.target.value))}
                    />
                  </div>
                )}

                {!!count && count > pageLimit && (
                  <div style={{marginLeft: 8}}>
                    <Pagination
                      totalRecords={count}
                      pageLimit={pageLimit}
                      currentPage={pageNumber}
                      onPageChanged={(data) => changePage(data)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 24}}/>

          <div className="Form__field Form__field_last"
               style={{
                 height: 360,
                 overflowY: "auto"
               }}>
            <ul className="List">
              {list.map((item, index) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    onClick={() => changeAdd(item)}
                    className={"List__item"}
                    style={{
                      position: "relative",
                      padding: "4px 8px",
                      border: "1px solid #dbdbdb",
                      backgroundColor: "white",
                      cursor: "pointer",
                      marginBottom: 6,
                      borderRadius: 5,
                    }}>
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          {item.id}.&nbsp;
                          {(!!item.name && (
                              <TextSearchMarker
                                text={item.name}
                                search={searchQuery}
                              />
                            )) ||
                            "-"}
                          {!!item.basic_specification && (
                            <div
                              className="Marker Marker_left Marker_inline Marker_small"
                            />
                          )}

                          {!!item.photos && (
                            <Tooltip text={'Фото'} style={{height: 14, marginLeft: 8}}>
                              <Icon
                                name={"custom-attach-file"}
                                color={"#202020"}
                                size={14}
                              />
                            </Tooltip>
                          )}
                        </div>

                        {!!item.description && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              marginTop: 2,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 700,
                                }}
                              >
                                Описание:&nbsp;
                              </div>
                              <TextSearchMarker
                                text={item.description}
                                search={searchQuery}
                              />
                            </div>
                          </div>
                        )}

                        {!!item.structure && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              marginTop: 2,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 700,
                                }}
                              >
                                Состав:&nbsp;
                              </div>
                              <TextSearchMarker
                                text={item.structure}
                                search={searchQuery}
                              />
                            </div>
                          </div>
                        )}

                        {!!item.recommend && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              marginTop: 2,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 700,
                                }}
                              >
                                Рекомендации:&nbsp;
                              </div>
                              <TextSearchMarker
                                text={item.recommend}
                                search={searchQuery}
                              />
                            </div>
                          </div>
                        )}


                        {(!!item.value || !!item.price || !!item.price_opt
                          || !!item.price_sale || !!item.price_sale_opt || (!!item.is_pcs && item.is_pcs)) && (
                          <div
                            style={{
                              width: "100%",
                              opacity: 0.75,
                              marginTop: 4,
                              fontWeight: 700,
                              fontSize: 19,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            {!!item.is_pcs && item.is_pcs && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#0061ff",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 4px 0 0",
                                }}
                              >
                                Штучно
                              </div>
                            )}
                            <span>
                  {!!item.value && !!item.measurement_unit_type ? (item.value / item.measurement_unit_type.ratio) : !!item.value && item.value}
                              {!!item.measurement_unit_type && <span style={{
                                marginLeft: 4,
                                fontWeight: 400,
                                fontSize: 13
                              }}>{item.measurement_unit_type.designation + '.'}</span>}
              </span>

                            {!!item.price && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                              (item.price / 100).toFixed(2))}<span
                              style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>}

                            {!!item.price_sale && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                              (item.price_sale / 100).toFixed(2))}<span
                              style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (акция)</span></span>}

                            {!!item.price && !!item.price_opt && <span style={{marginLeft: 16}}>|</span>}

                            {!!item.price_opt && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                              (item.price_opt / 100).toFixed(2))}<span
                              style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (опт)</span></span>}

                            {!!item.price_sale_opt && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                              (item.price_sale_opt / 100).toFixed(2))}<span
                              style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (опт, акция)</span></span>}
                          </div>
                        )}

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >

                          {!!item.seo_url && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#dbdbdb",
                                display: "inline-block",
                                color: "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {(!!item.seo_url && item.seo_url) || "-"}
                            </div>
                          )}

                          {!!item.nomenclatures && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "#ffffff",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Комплект
                            </div>
                          )}

                          {!!item.is_published && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#4caf50",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              На розницу
                            </div>
                          )}

                          {!!item.is_published_opt && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#cf9400",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              На опт
                            </div>
                          )}


                          {!!item.is_new && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#ffe082",
                                display: "inline-block",
                                color: "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Новинка
                            </div>
                          )}
                          {!!item.is_buyer_choice && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#f58ea6",
                                display: "inline-block",
                                color: "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Хит
                            </div>
                          )}


                          {!!item.nomenclature_category && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {(!!item.nomenclature_category.title && item.nomenclature_category.title) || "-"}
                            </div>
                          )}

                          {!!item.is_deleted && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#f44336",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Удалено в интеграции
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__size">
                        <div className="Size__title">
                          {(!!item.code || !!item.article) && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {!!item.code && <TextSearchMarker text={item.code} search={searchQuery}/>}
                              {!!item.code && !!item.article && " | "}
                              {!!item.code && <TextSearchMarker text={item.article} search={searchQuery}/>}
                            </div>
                          )}
                        </div>

                        <>

                          <div style={{
                            height: 32,
                            width: 2,
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}/>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: ((!!item.quantity && (item.quantity > 0 ? "#2e7d3233" : "#bf360c33")) || "#20202010"),
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {!item.is_pcs && (
                                <>
                                  {(!!item.quantity && !!item.measurement_unit_type ? (item.quantity / item.measurement_unit_type.ratio).toFixed(3) : !!item.quantity && item.quantity.toFixed(3)) || "0"}
                                  {!!item.measurement_unit_type && <span style={{
                                    marginLeft: 4,
                                    fontWeight: 400,
                                    fontSize: 13
                                  }}>{item.measurement_unit_type.designation + '.'}</span>}
                                </>
                              )}

                              {item.is_pcs && (
                                <>
                                  {(!!item.quantity && (item.quantity)) || "0"}
                                </>
                              )}
                            </div>
                            <div style={{
                              fontSize: 12,
                              fontWeight: 600,
                            }}>
                              кол.
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </li>
                </Fragment>
              ))}
            </ul>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalOrderItemSelect;
