import React, {Fragment, useState} from "react";
import {
  ButtonIcon,
  ButtonIconExport,
  Loading,
  Pagination,
  Search,
  Select,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import moment from "moment/moment";
import ModalCowAdd from "./ModalCowAdd";
import ModalCowEdit from "./ModalCowEdit";
import {ROOT_URL} from "../../../config/url";

function CowsPage(props) {
  const {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  } = props;


  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            КРС
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />

            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

            <ButtonIconExport
              tooltipText={"Выгрузить"}
              iconName={"custom-download"}
              iconColor={"#202020"}
              size={24}
              style={{marginLeft: 12}}
              apiUrl={
                "/api/cows/export/xlsx"
              }
              rootUrl={ROOT_URL}
              mimeType={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              fileName={(
                "КРС_на_" +
                moment().format("DD_MM_YYYY") +
                ".xlsx"
              ).toLowerCase()}
            />
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      backgroundColor: item.is_publish && "#ffe082",
                      position: "relative",
                    }}
                  >
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          {!!item.name && (
                            <TextSearchMarker
                              text={item.name}
                              search={searchQuery}
                            />)}
                          <span style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginLeft: 4
                          }}>
                          (<TextSearchMarker
                            text={item.id}
                            search={searchQuery}
                          />)
                        </span>
                          {!!item.birthdate && (
                            <div style={{
                              fontSize: 12,
                              fontWeight: 400,
                            }}>
                              Дата&nbsp;рождения:&nbsp;{(!!item.birthdate && moment(item.birthdate.substring(0, 19)).format("DD.MM.YYYY")) || "-"}
                            </div>
                          )}
                          {!!item.chip_code && (
                            <div style={{
                              fontSize: 12,
                              fontWeight: 400,
                            }}>
                              Код&nbsp;с&nbsp;чипа:&nbsp;
                              <TextSearchMarker
                                text={item.chip_code}
                                search={searchQuery}
                              />
                            </div>
                          )}
                          {!!item.unm_code && (
                            <div style={{
                              fontSize: 12,
                              fontWeight: 400,
                            }}>
                              УНСМ&nbsp;номер:&nbsp;
                              <TextSearchMarker
                                text={item.unm_code}
                                search={searchQuery}
                              />
                            </div>
                          )}
                          {!!item.note && (
                            <div style={{
                              fontSize: 12,
                              fontWeight: 400,
                            }}>
                              <TextSearchMarker
                                text={item.note}
                                search={searchQuery}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {!!item.parent_fe && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.parent_fe.name && item.parent_fe.name) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Мама
                            </div>
                          </div>
                        )}

                        {!!item.parent_ma && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.parent_ma.name && item.parent_ma.name) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Папа
                            </div>
                          </div>
                        )}


                        {(!!item.parent_fe || !!item.parent_ma) && (
                          <div style={{
                            height: 32,
                            width: 2,
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                          </div>
                        )}


                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.weight && new Intl.NumberFormat("ru-RU").format(
                                (item.weight / 1000).toFixed(3)
                              )) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>Вес&nbsp;(кг)
                          </div>
                        </div>

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.cow_type &&
                                <TextSearchMarker
                                  text={item.cow_type.name}
                                  search={searchQuery}
                                />) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>Группа
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          {/*<div*/}
                          {/*  className="Button__text Button__text_right"*/}
                          {/*  onClick={() => setModalOrderItemAdd(item)}*/}
                          {/*>*/}
                          {/*  Добавить позицию*/}
                          {/*</div>*/}
                        </div>
                        <div className="Header__settings">
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{marginLeft: 12}}
                            onClick={() => setModalItemEdit(item)}
                          />
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalCowEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalCowAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default CowsPage;
