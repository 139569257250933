import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import ModalCowParentSelect from "./ModalCowParentSelect";

function ModalCowEdit(props) {

  const inputDate = useRef(null);
  const inputName = useRef(null);
  const inputChipCode = useRef(null);
  const inputUnmCode = useRef(null);
  const inputNote = useRef(null);
  const inputWeight = useRef(null);
  const inputCowGenderTypeTag = useRef(null);


  const [selectParentFe, setSelectParentFe] = useState(null)
  const [isModalSelectParentFe, setIsModalSelectParentFe] = useState(false)

  const [selectParentMa, setSelectParentMa] = useState(null)
  const [isModalSelectParentMa, setIsModalSelectParentMa] = useState(false)

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/cow/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: response => {
      if (!!response) {
        setSelectParentFe((!!response.parent_fe && response.parent_fe) || null)
        setSelectParentMa((!!response.parent_ma && response.parent_ma) || null)
      }
    },
    callbackError: () => {
      props.handleClose();
    },
  });


  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeEdit = () => {
    const date = jsonFormatText(inputDate.current.value);
    const name = jsonFormatText(inputName.current.value);
    const chipCode = jsonFormatText(inputChipCode.current.value);
    const unmCode = jsonFormatText(inputUnmCode.current.value);
    const note = jsonFormatText(inputNote.current.value);
    const weight = jsonFormatText(inputWeight.current.value);
    let cowGenderTypeTag = inputCowGenderTypeTag.current.value;
    cowGenderTypeTag = cowGenderTypeTag.length > 0
      ? cowGenderTypeTag === "null"
        ? null
        : cowGenderTypeTag
      : null

    // if ((!cowGenderTypeTag
    //     || (!!cowGenderTypeTag && cowGenderTypeTag === "null"))
    //   || isValidateNullText(date)
    //   || isValidateNullText(name)) {
    //   notification("Заполните все обязательные поля", "red");
    //   return;
    // }

    const jsonData = JSON.stringify({
      name: name,
      chip_code: chipCode,
      unm_code: unmCode,
      note: note,
      birthdate: date,
      weight: numberConvertV(weight),
      cow_type: !!cowGenderTypeTag ? {
        tag: cowGenderTypeTag,
      } : null,
      //   parent_ma: !!selectParentMa ? {
      //     uuid: selectParentMa.uuid,
      //   } : null,
      //   parent_fe: !!selectParentFe ? {
      //     uuid: selectParentFe.uuid,
      //   } : null,
    });

    axios
      .put("/api/cow/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/cow/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("КРС удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const selectOptions = () => {
    return [{
      value: "null",
      title: "Не выбран",
    },
      {
        value: "BULL",
        title: "Бык-производитель",
      },
      {
        value: "COW",
        title: "Корова",
      },
      {
        value: "NETEL",
        title: "Нетель",
      },
      {
        value: "HEIFER",
        title: "Телка",
      },
      {
        value: "STEER",
        title: "Бычок",
      }
    ];
  };


  return (
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && (
            <div className="Form">
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {!!object.name && object.name}({object.id})&nbsp;-&nbsp;{!!object.birthdate && moment(object.birthdate.substring(0, 19)).format("DD.MM.YYYY")}
                <div className="Text__signature">КРС</div>
              </div>

              <div className="Form__field">
                <Input reference={inputName} label="Код КРС (имя / номер бирки)"
                       value={!!object.name && object.name}/>
              </div>

              <div className="Form__field">
                <Input reference={inputChipCode} label="Код с чипа"
                       value={!!object.chip_code && object.chip_code}/>
              </div>

              <div className="Form__field">
                <Input reference={inputUnmCode} label="УНСМ номер"
                       value={!!object.unm_code && object.unm_code}/>
              </div>

              <div className="Form__field">
                <Textarea reference={inputNote} label="Примечание"
                          value={!!object.note && object.note}/>
              </div>

              <div className="Form__field">
                <Input reference={inputDate} type={'date'} isFixed={true} label="Дата рождения"
                       value={
                         !!object.birthdate
                           ? moment(object.birthdate.substring(0, 19)).format(
                             "YYYY-MM-DD"
                           )
                           : null
                       }/>
              </div>


              <div className="Form__field">
                <Input type={'number'} reference={inputWeight} label="Вес (кг)"
                       value={!!object.weight && (object.weight / 1000).toFixed(3)}/>
              </div>

              <div className="Form__field">
                <Select
                  isFixed={true}
                  reference={inputCowGenderTypeTag}
                  options={selectOptions()}
                  label="Половозрастная группа"
                  value={!!object.cow_type && object.cow_type.tag}
                />
              </div>

              {/*{!selectParentMa && (*/}
              {/*  <div className="Form__field"*/}
              {/*       style={{*/}
              {/*         cursor: "pointer"*/}
              {/*       }}*/}
              {/*       onClick={() => setIsModalSelectParentMa(true)}>*/}
              {/*    <div className="Field__label">*/}
              {/*      КРС папа*/}
              {/*    </div>*/}
              {/*    <div className="Field__text">*/}
              {/*      -*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*{!!selectParentMa && (*/}
              {/*  <div className="Form__field"*/}
              {/*       style={{*/}
              {/*         cursor: "pointer"*/}
              {/*       }}*/}
              {/*       onClick={() => setIsModalSelectParentMa(true)}>*/}
              {/*    <div className="Field__label">*/}
              {/*      КРС папа*/}
              {/*    </div>*/}
              {/*    <div className="Field__text">*/}
              {/*      {!!selectParentMa.name && selectParentMa.name}({selectParentMa.id})*/}
              {/*      &nbsp;-&nbsp;{!!selectParentMa.birthdate && moment(selectParentMa.birthdate.substring(0, 19)).format("DD.MM.YYYY")}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*{!selectParentFe && (*/}
              {/*  <div className="Form__field Form__field_last"*/}
              {/*       style={{*/}
              {/*         cursor: "pointer"*/}
              {/*       }}*/}
              {/*       onClick={() => setIsModalSelectParentFe(true)}>*/}
              {/*    <div className="Field__label">*/}
              {/*      КРС мама*/}
              {/*    </div>*/}
              {/*    <div className="Field__text">*/}
              {/*      -*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*{!!selectParentFe && (*/}
              {/*  <div className="Form__field Form__field_last"*/}
              {/*       style={{*/}
              {/*         cursor: "pointer"*/}
              {/*       }}*/}
              {/*       onClick={() => setIsModalSelectParentFe(true)}>*/}
              {/*    <div className="Field__label">*/}
              {/*      КРС мама*/}
              {/*    </div>*/}
              {/*    <div className="Field__text">*/}
              {/*      {!!selectParentFe.name && selectParentFe.name}({selectParentFe.id})*/}
              {/*      &nbsp;-&nbsp;{!!selectParentFe.birthdate && moment(selectParentFe.birthdate.substring(0, 19)).format("DD.MM.YYYY")}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue"
                        onClick={() => changeEdit()}>
                  Применить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>
              </div>
            </div>
          )}
          {isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {!!object.name && object.name}({object.id})&nbsp;-&nbsp;{!!object.birthdate && moment(object.birthdate.substring(0, 19)).format("DD.MM.YYYY")}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Удалить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>

        {isModalSelectParentMa && (
          <ModalCowParentSelect
            item={selectParentMa}
            typeTag={"MALE"}
            successfulRequest={item =>
              setSelectParentMa(item)
            }
            handleClose={() => setIsModalSelectParentMa(false)}
          />
        )}

        {isModalSelectParentFe && (
          <ModalCowParentSelect
            item={selectParentFe}
            typeTag={"FEMALE"}
            successfulRequest={item =>
              setSelectParentFe(item)
            }
            handleClose={() => setIsModalSelectParentFe(false)}
          />
        )}
      </>
    )
  );
}

export default ModalCowEdit;
