import { useEffect } from "react";

function useEnterKey(onEnter) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        onEnter(); // Вызываем переданную функцию
      }
    };

    // Добавляем обработчик события
    window.addEventListener("keydown", handleKeyDown);

    // Убираем обработчик при размонтировании
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEnter]); // Добавляем зависимость

  // Хук ничего не возвращает, только выполняет логику
}

export default useEnterKey;
