import React, {useEffect, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {getErrorMessageByKey, Loading, Modal, useList, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalOrderItemSelect from "./ModalOrderItemSelect";
import ScannerInput from "./compoments/ScannerInput";
import {ModalAddOrderItem3} from "./ModalAddOrderItem3";
import useEscapeKey from "../../../../hooks/useEscapeKey";
import useEnterKey from "../../../../hooks/useEnterKey";
import useSpaceKey from "../../../../hooks/useSpaceKey";

function parsePCARDData(data) {
  const startMarker = "BEGIN:PCARD";
  const endMarker = "END:PCARD";

  // Находим начало и конец блока с данными
  const startIndex = data.indexOf(startMarker);
  const endIndex = data.indexOf(endMarker);

  // Если маркеры найдены, извлекаем строку между ними
  if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
    const pcardContent = data.slice(startIndex + startMarker.length, endIndex).trim();

    // Создаем объект для хранения данных
    const parsedData = {};

    // Разбиваем строку по строкам и обрабатываем каждую строку
    const lines = pcardContent.split("\n");
    lines.forEach(line => {
      // Разделяем строку по первому двоеточию
      const [key, value] = line.split(":");

      if (key && value) {
        // Очищаем пробелы и записываем в объект, если значение не пустое
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();

        if (trimmedValue !== "") {
          parsedData[trimmedKey] = trimmedValue;
        }
      }
    });

    return parsedData;
  }

  // Если маркеры не найдены, возвращаем пустой объект
  return {};
}

export const ModalAddOrderSearch3 = (props) => {
  const [scannedData, setScannedData] = useState(null);
  const [selectItemUuid, setSelectItemUuid] = useState(null);
  const [isChangeScan, setIsChangeScan] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [isRequest, setIsRequest] = useState(false);

  const {notification} = useNotification();

  const handleScan = (data) => {
    // scannedData нужна когда пойдут QR с данными.
    setScannedData((!!data && data !== "" && data) || null);
    let itemUuid = null;
    if (!!data && data !== "") {
      if (!!parsePCARDData(data).UNIQUEUUID) {
        itemUuid = parsePCARDData(data).UNIQUEUUID
      } else {
        notification("Не корректный QR", "red");
      }
    }
    setSelectItemUuid(itemUuid);
  };

  const [isModalSelectItem, setIsModalSelectItem] = useState(false);
  const [isModalOrderItem3Add, setIsModalOrderItem3Add] = useState(false);

  useEffect(() => {
    if (!!selectItemUuid) {
      setIsRequest(true);
      axios.get("/api/nomenclatures", {
        withCredentials: false,
        baseURL: ROOT_URL,
        params: {
          query: selectItemUuid
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      }).then(
        (response) => {
          if (!!response.data && response.data.length > 0) {
            setSelectItem(response.data[0]);
          } else {
            setSelectItem(null);
            setScannedData(null);
            notification("Товар не найден", "red");
          }
        },
      ).catch(() => {
        setSelectItem(null);
        setScannedData(null);
        notification("Товар не найден", "red");
      }).finally(() => {
        setSelectItemUuid(null);
        setIsRequest(false);
      });
    }
  }, [selectItemUuid]);

  useEffect(() => {
    if (!!selectItem) {
      setIsModalOrderItem3Add(true);
    }
  }, [selectItem]);

  const {
    list: [list],
  } = useList({
    apiUrl: "api/nomenclatures/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/realization-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
  });

  const changeAdd = (event) => {
    event.preventDefault();

    if (!selectItem) {
      notification("Укажите номенклатуру", "red");
      return;
    }

    const jsonData = JSON.stringify({
      nomenclature: {
        uuid: selectItem.uuid,
      },
    });

    axios
      .post("/api/realization-order/" + props.object.uuid + "/item/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const isAutoScan = () => {
    return !isModalSelectItem && !isModalOrderItem3Add
  }

  const isFocusDisable = () => {
    return isModalSelectItem || isModalOrderItem3Add
  }

  useEscapeKey(() => {
    if (!isModalOrderItem3Add) {
      props.handleClose();
    }
  });

  useSpaceKey(() => {
    if (!!selectItem) {
      setIsModalOrderItem3Add(true);
    }
  });

  return (
    !!list &&
    !!object && (
      <>
        <Modal handleClose={() => null} contentStyle={{
          width: 420
        }}>
          <div className="Form">
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Добавить позицию на реализацию</div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Поиск по QR
              </div>
              {!isRequest && (
                <div className="Field__text" style={{
                  height: 68,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <ScannerInput onScan={handleScan} isChangeScan={isChangeScan} isAutoScan={isAutoScan()} isFocusDisable={isFocusDisable()}/>
                </div>
              )}
              {isRequest && (
                <div className="Field__text" style={{
                  height: 68,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <div style={{
                    position: "absolute"
                  }}>
                    <Loading size="medium" color="blue"/>
                  </div>
                </div>
              )}
            </div>

            {!!selectItem && (
              <div className="Form__field">
                <div className="Field__label">
                  Последний выбранный товар
                </div>
                <div className="Field__text">
                  <div className="Action__button Action__button_gray"
                  onClick={() => setIsModalOrderItem3Add(true)}>
                    {selectItem.id + ". " + selectItem.name + (!!selectItem.article ? " - " + selectItem.article : "")}
                  </div>
                </div>
              </div>
            )}

            <div className="Form__field">
              <div className="Field__text" style={{
                textAlign: "center"
              }}>
                или используйте <a style={{color: "#0061ff", cursor: "pointer"}}
                                   onClick={() => setIsModalSelectItem(true)}>ручной поиск</a>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Закрыть
              </div>
            </div>
          </div>
        </Modal>

        {isModalSelectItem && (
          <ModalOrderItemSelect
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => (setIsModalSelectItem(false), setIsChangeScan(!isChangeScan))}
          />
        )}

        {isModalOrderItem3Add && (
          <ModalAddOrderItem3
            object={props.object}
            item={selectItem}
            successfulRequest={props.successfulRequest}
            handleClose={() => (setIsModalOrderItem3Add(false), setIsChangeScan(!isChangeScan))}
          />
        )}
      </>
    )
  );
};

