import React, {Fragment} from "react";
import {ButtonIcon, Loading,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalRealizationEdit from "../RealizationOrderItem/ModalRealizationEdit";
import {ModalAddOrderItem2} from "../RealizationOrderItem/ModalAddOrderItem2";
import ModalOrderItemTransactionRollback from "../RealizationOrderItem/ModalOrderItemTransactionRollback";
import ModalOrderItemTransaction from "../RealizationOrderItem/ModalOrderItemTransaction";
import ModalOrderItemEdit3 from "../RealizationOrderItem/ModalOrderItemEdit3";
import {RealizationOrderItem} from "../RealizationOrderItem";
import ModalOrderEditPayment from "../RealizationOrderItem/ModalOrderEditPayment";
import {ModalAddOrderItem3} from "../RealizationOrderItem/ModalAddOrderItem3";
import {ModalAddOrderSearch3} from "../RealizationOrderItem/ModalAddOrderSearch3";
import {NavLink} from "react-router-dom";
import ModalOrderEditPaymentNew from "../RealizationOrderItem/ModalOrderEditPaymentNew";

function RealizationOrderView(props) {
  const {
    isNotFound,
    object,
    realizationOrderUuid,

    searchQuery,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Add, setModalOrderItem3Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
    modalOrderEditPayment, setModalOrderEditPayment,
    modalOrderEditPaymentNew, setModalOrderEditPaymentNew
  } = props;

  function isBoningOrderTransaction(object) {
    let isTransaction = false

    if (!!object.items) {
      object.items.forEach(i => {
        if (i.is_transaction) {
          isTransaction = true
        }
      })
    }

    return isTransaction
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        {isNotFound && !object && (
          <div className="Page__loader">
            <div style={{
              fontSize: 36,
              opacity: .25,
            }}>Реализация не найдена
            </div>
            <span style={{
              display: "flex"
            }}>перейти к <a href={"/realization-orders"} style={{
              color: "#0061ff",
              textDecoration: "underline",
              marginLeft: 4
            }}>основному списку</a>
            </span>
          </div>
        )}

        {isNotFound && !!object && (
          <div className="Page__loader">
            <div style={{
              fontSize: 36,
              opacity: .25,
            }}>Реализация удалена
            </div>
            <span style={{
              display: "flex"
            }}>перейти к <a href={"/realization-orders"} style={{
              color: "#0061ff",
              textDecoration: "underline",
              marginLeft: 4
            }}>основному списку</a>
            </span>
          </div>
        )}

        {!isNotFound && !object && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!isNotFound && !!object && (
          <Fragment>
            <div className="Page__title">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 21,
                  fontWeight: 700,
                }}
              >
                Реализация
              </div>

              <div className="Title__settings">
                <ButtonIcon
                  tooltipText={"Обновить"}
                  iconName={"custom-change-circle"}
                  iconColor={"#20202075"}
                  size={28}
                  style={{marginLeft: 8}}
                  onClick={() => (setIsUpdateObject(!isUpdateObject))}
                />

                <NavLink
                  style={{marginLeft: 12}}
                  className="Button__text"
                  to={"/realization-orders"}>
                  Закрыть
                </NavLink>
              </div>
            </div>
            <div className="Page__content">
              <ul className="List">
                <RealizationOrderItem {...props} objectUuid={realizationOrderUuid}/>
              </ul>
            </div>
          </Fragment>
        )}

        {!!modalItemEdit && (
          <ModalRealizationEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}

        {!!modalOrderItem2Add && (
          <ModalAddOrderItem2
            object={modalOrderItem2Add}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem2Add(null)}
          />
        )}

        {!!modalOrderItem3Add && (
          <ModalAddOrderSearch3
            object={modalOrderItem3Add}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem3Add(null)}
          />
        )}

        {!!modalOrderItem3Edit && (
          <ModalOrderItemEdit3
            object={modalOrderItem3Edit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem3Edit(null)}
          />
        )}

        {!!modalItemTransaction && (
          <ModalOrderItemTransaction
            object={modalItemTransaction}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransaction(null)}
          />
        )}

        {!!modalItemTransactionRollback && (
          <ModalOrderItemTransactionRollback
            object={modalItemTransactionRollback}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransactionRollback(null)}
          />
        )}

        {!!modalOrderEditPayment && (
          <ModalOrderEditPayment
            object={modalOrderEditPayment}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderEditPayment(null)}
          />
        )}

        {!!modalOrderEditPaymentNew && (
          <ModalOrderEditPaymentNew
            object={modalOrderEditPaymentNew.object}
            paymentType={modalOrderEditPaymentNew.type}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderEditPaymentNew(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default RealizationOrderView;
