import { useEffect } from "react";

function useSpaceKey(onSpace) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Проверяем нажатие пробела
      if (event.key === " " || event.code === "Space") {
        event.preventDefault(); // Можно предотвратить прокрутку, если требуется
        onSpace(); // Вызываем переданную функцию
      }
    };

    // Добавляем обработчик события
    window.addEventListener("keydown", handleKeyDown);

    // Убираем обработчик при размонтировании
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSpace]); // Добавляем зависимость

  // Хук ничего не возвращает, только выполняет логику
}

export default useSpaceKey;
