import React, {useRef} from "react";
import {ButtonIcon, Loading, Modal, Pagination, Search, Select, TextSearchMarker, useList,} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../../config/url";

function ModalContractorItemSelect(props) {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/contractors",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 10,
    isSearch: true,
  });

  const changeAdd = (item) => {
    props.successfulRequest(item);
    props.handleClose();
  };

  const inputSearchText = useRef(null);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        width: 740
      }}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Выбор контрагента
          </div>

          <div className="Form__field">
            <div className="Page__title">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 21,
                  fontWeight: 700,
                }}
              >
                Контрагенты
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                  ({count})
                </span>
                )}
                <span style={{width: 220, marginLeft: 16}}>
                <Search
                  reference={inputSearchText}
                  type="text"
                  label={"Поиск"}
                  isFixed={true}
                  onChange={() => onChangeSearchText()}
                />
              </span>
              </div>

              <div className="Title__settings">
                <ButtonIcon
                  tooltipText={"Обновить"}
                  iconName={"custom-change-circle"}
                  iconColor={"#20202075"}
                  size={28}
                  style={{marginLeft: 8}}
                  onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
                />

                {!!count && !!pageLimit && (
                  <div
                    style={{
                      marginLeft: 12,
                      width: 64,
                    }}
                  >
                    <Select
                      isFixed={true}
                      isDefaultOption={false}
                      value={pageLimit}
                      options={[
                        {
                          value: 10,
                          title: 10,
                        },
                        {
                          value: 25,
                          title: 25,
                        },
                        {
                          value: 50,
                          title: 50,
                        },
                        {
                          value: 75,
                          title: 75,
                        },
                        {
                          value: 100,
                          title: 100,
                        },
                      ]}
                      label="Лимит"
                      onChange={(event) => setPageLimit(Number(event.target.value))}
                    />
                  </div>
                )}

                {!!count && count > pageLimit && (
                  <div style={{marginLeft: 8}}>
                    <Pagination
                      totalRecords={count}
                      pageLimit={pageLimit}
                      currentPage={pageNumber}
                      onPageChanged={(data) => changePage(data)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 24}}/>

          <div className="Form__field Form__field_last"
               style={{
                 height: 360,
                 overflowY: "auto"
               }}>
            {!list && (
              <div className={"Page__loader"}>
                <Loading/>
              </div>
            )}

            {list.map((item, index) => (
              <div key={"item_" + index} onClick={() => changeAdd(item)} style={{
                padding: "4px 8px",
                border: "1px solid #dbdbdb",
                cursor: "pointer",
                marginBottom: 6,
                borderRadius: 5,
              }}>
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    width: "100%",
                  }}
                >
                  {!!item.short_name && (
                    <TextSearchMarker
                      text={item.short_name}
                      search={searchQuery}
                    />)}
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}>
                    {!!item.name && (
                      <TextSearchMarker
                        text={item.name}
                        search={searchQuery}
                      />)}
                  </div>
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}>
                    <span style={{marginRight: 8}}>ОГРН:&nbsp;{(!!item.ogrn && item.ogrn) || "-"}</span>
                    <span style={{marginRight: 8}}>ИНН:&nbsp;{(!!item.inn && item.inn) || "-"}</span>
                    <span style={{marginRight: 8}}>КПП:&nbsp;{(!!item.kpp && item.kpp) || "-"}</span>
                  </div>
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}>
                    <span style={{marginRight: 8}}>Банк:&nbsp;{(!!item.bank && item.bank) || "-"}</span>
                    <span style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!item.invoice_number && item.invoice_number) || "-"}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {!!props.item && (
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => (props.successfulRequest(null), props.handleClose())}
              >
                Удалить
              </div>
            </div>
          )}

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalContractorItemSelect;
