import {useEffect, useRef, useState} from "react";
import {ROOT_URL} from "../../../../config/url";
import {useAction, useList, useLocalStorage, useNotification, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function useRealizationOrders() {
  const {notification} = useNotification();

  const inputSearchText = useRef(null);

  const [filterRealizationOrders, setFilterRealizationOrders] = useLocalStorage(
    "realization-orders",
    {
      isChart: false
    }
  );

  const [isChart, setIsChart] = useState(filterRealizationOrders.isChart || false);

  useEffect(() => {
    setFilterRealizationOrders({
      isChart: isChart
    })
  }, [isChart]);

  const [selectYear, setSelectYear] = useState(moment().format('YYYY'));
  const [selectMonth, setSelectMonth] = useState(moment().format('MM'));
  const [selectDay, setSelectDay] = useState(null);

  const [selectContractor, setSelectContractor] = useState(null);
  const [filterContractor, setFilterContractor] = useState(null);

  const [selectPaid, setSelectPaid] = useState(null);

  const [selectPayment, setSelectPayment] = useState(null);
  const [filterPayment, setFilterPayment] = useState(null);

  const [selectPaymaster, setSelectPaymaster] = useState(null);
  const [filterPaymaster, setFilterPaymaster] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  const getUrlParams = () => {
    let params = {};

    if (!!searchQuery) {
      params = {
        ...params,
        query: searchQuery,
      };
    }

    if (!!selectYear) {
      params = {
        ...params,
        year: selectYear,
      };
    }
    if (!!selectMonth) {
      params = {
        ...params,
        month: selectMonth,
      };
    }
    if (!!selectDay) {
      params = {
        ...params,
        day: selectDay,
      };
    }
    if (!!filterContractor) {
      params = {
        ...params,
        contractor: filterContractor,
      };
    }
    if (!!selectPaid) {
      params = {
        ...params,
        paid: selectPaid,
      };
    }
    if (!!filterPayment) {
      params = {
        ...params,
        payment: filterPayment,
      };
    }
    if (!!filterPaymaster) {
      params = {
        ...params,
        paymaster: filterPaymaster,
      };
    }

    return params;
  }

  const {
    list: [listYears]
  } = useList({
    apiUrl: "/api/realization-orders/years",
    rootUrl: ROOT_URL,
  });

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    params: [_listParams, setListParams],
  } = useList({
    apiUrl: "/api/realization-orders",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 10,
    urlParams: getUrlParams()
  });

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
    params: [_objectParams, setObjectParams],
  } = useObject({
    apiUrl: "/api/realization-orders/chart",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams()
  });

  useEffect(() => {
    setPageNumber(1);
    setListParams({...getUrlParams()});
    setObjectParams({...getUrlParams()});
  }, [searchQuery, selectYear, selectMonth, selectDay, filterContractor, selectPaid, filterPayment, filterPaymaster]);

  const changeSelectYear = (year) => {
    setSelectYear(year);
  };

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value.trim());
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const {unrolls, isUnroll, changeUnroll, changeAllUnroll} = useUnroll();

  const {setAction} = useAction(["realization"], () => (updateList(), updateObject()));
  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "realization"]);
    } else {
      setAction(["realization"]);
    }
  }, [list]);

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalOrderItem2Add, setModalOrderItem2Add] = useState(null);
  const [modalOrderItem3Add, setModalOrderItem3Add] = useState(null);
  const [modalOrderItem3Edit, setModalOrderItem3Edit] = useState(null);
  const [modalAddBoning, setModalAddBoning] = useState(null);
  const [modalItemTransaction, setModalItemTransaction] = useState(null);
  const [modalItemTransactionRollback, setModalItemTransactionRollback] = useState(null);
  const [modalOrderEditAction, setModalOrderEditAction] = useState(null);
  const [modalOrderEditPayment, setModalOrderEditPayment] = useState(null);
  const [modalOrderEditPaymentNew, setModalOrderEditPaymentNew] = useState(null);
  const [isModalFilterEdit, setIsModalFilterEdit] = useState(false);

  // ----------------------------------------------------------------------------------------------------

  const [toDay] = useState(moment);
  const [calendarDays, setCalendarDays] = useState(
    new Date(Number(selectYear), Number(selectMonth), 0).getDate()
  );
  const [days, setDays] = useState(null);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  const changeSelectDay = (day) => {
    setSelectDay((selectDay !== day && day) || null);
  };

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  const isDayMarker = (list, selectYear, selectMonth, day) => {
    let isDayMarker = false;

    if (!!list) {
      list.map((item) => {
        if (
          Number(moment(item.date.substring(0, 19)).format("YYYY")) ===
          Number(selectYear) &&
          Number(moment(item.date.substring(0, 19)).format("MM")) ===
          Number(selectMonth) &&
          Number(moment(item.date.substring(0, 19)).format("DD")) === Number(day)
        ) {
          isDayMarker = item.count_orders > 0;
        }
      });
    }

    return isDayMarker;
  };

  const getDayCount = (list, selectYear, selectMonth, day) => {
    let dayCount = 0;

    if (!!list) {
      list.map((item) => {
        if (
          Number(moment(item.date.substring(0, 19)).format("YYYY")) ===
          Number(selectYear) &&
          Number(moment(item.date.substring(0, 19)).format("MM")) ===
          Number(selectMonth) &&
          Number(moment(item.date.substring(0, 19)).format("DD")) === Number(day)
        ) {
          dayCount = item.count_orders;
        }
      });
    }

    return String(dayCount) === "0" ? "" : String(dayCount);
  };

  const getDayAmount = (list, selectYear, selectMonth, day) => {
    let dayCount = 0;

    if (!!list) {
      list.map((item) => {
        if (
          Number(moment(item.date.substring(0, 19)).format("YYYY")) ===
          Number(selectYear) &&
          Number(moment(item.date.substring(0, 19)).format("MM")) ===
          Number(selectMonth) &&
          Number(moment(item.date.substring(0, 19)).format("DD")) === Number(day)
        ) {
          dayCount = item.count_amounts;
        }
      });
    }

    return String(dayCount) === "0" ? "" : String(dayCount);
  };

  const getAllCount = (listCalendar) => {
    let allCount = 0;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        allCount += point.count_orders
      });
    }

    return allCount;
  };

  const getOptionDays = () => {
    const optionDays = [];
    if (!!days) {
      days.forEach(d => {
        optionDays.push(d.day)
      })
    }
    return optionDays;
  }

  const getOptionMonths = () => {
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  }

  const getOptionOrders = () => {
    const optionDays = [];

    if (!!object && object.is_year) {
      if (!!days && !!object.date_orders) {
        getOptionMonths().forEach(m => {
          optionDays.push(Number(getDayCount(object.date_orders, selectYear, m, '01')))
        })
      }
    }

    if (!!object && object.is_month) {
      if (!!days && !!object.date_orders) {
        days.forEach(d => {
          optionDays.push(Number(getDayCount(object.date_orders, selectYear, selectMonth, d.day)))
        })
      }
    }

    return optionDays;
  }

  const getOptionAmounts = () => {
    const optionDays = [];

    if (!!object && object.is_year) {
      if (!!days && !!object.date_orders) {
        getOptionMonths().forEach(m => {
          optionDays.push(Number(getDayAmount(object.date_orders, selectYear, m, '01')) / 100)
        })
      }
    }

    if (!!object && object.is_month) {
      if (!!days && !!object.date_orders) {
        days.forEach(d => {
          optionDays.push(Number(getDayAmount(object.date_orders, selectYear, selectMonth, d.day)) / 100)
        })
      }
    }

    return optionDays;
  }

  const option = {
    grid: {
      left: '8px',
      right: '8px',
      bottom: '8px',
      top: '8px',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        data: !!object ? (object.is_year ? getOptionMonths() : getOptionDays()) : getOptionMonths(),
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        // name: 'Заказы',
        min: 0,
        max: !!object ? (object.is_year ? 800 : 120) : 800,
        interval: !!object ? (object.is_year ? 200 : 40) : 200,
        axisLabel: {
          formatter: '{value}'
        }
      },
      {
        type: 'value',
        // name: 'Сумма',
        min: 0,
        max: !!object ? (object.is_year ? 2000000 : 300000) : 2000000,
        interval: !!object ? (object.is_year ? 500000 : 100000) : 500000,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        name: 'Заказы',
        type: 'bar',
        data: getOptionOrders()
      },
      {
        yAxisIndex: 1,
        name: 'Сумма',
        type: 'bar',
        data: getOptionAmounts()
      },
    ],
    aria: {
      enabled: true,
      decal: {
        show: true
      }
    }
  };

  return {
    list,
    count,
    setList,
    setIsUpdateList,
    isUpdateList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    object,
    setObject,
    isUpdateObject,
    setIsUpdateObject,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Add, setModalOrderItem3Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
    modalOrderEditPayment, setModalOrderEditPayment,
    modalOrderEditPaymentNew, setModalOrderEditPaymentNew,

    listYears,
    changeSelectYear,

    selectMonth,
    setSelectMonth,

    selectDay,
    setSelectDay,

    selectContractor,
    setSelectContractor,
    filterContractor,
    setFilterContractor,

    selectPaid,
    setSelectPaid,

    selectPayment,
    setSelectPayment,
    filterPayment,
    setFilterPayment,

    selectPaymaster,
    setSelectPaymaster,
    filterPaymaster,
    setFilterPaymaster,

    isModalFilterEdit,
    setIsModalFilterEdit,

    changeSelectDay,
    weekDayText,
    selectYear,
    days,
    toDay,

    isDayMarker,
    getDayCount,
    getDayAmount,

    option,

    isChart,
    setIsChart,
  };
}

export default useRealizationOrders;
